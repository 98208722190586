<template v-if="formActive">
    <FormItem id="created_at" label="Date" v-model="log.formatted_date" :disabled="true" />
    <FormItem id="uri" label="URI" v-model="log.uri" :disabled="true" />
    <FormItem id="controller" label="Module" v-model="log.controller" :disabled="true" />
    <FormItem id="method" label="Action" v-model="log.method" :disabled="true" />
    <FormItem id="user" label="User" v-model="log.user.name" :disabled="true" />
    <FormItem id="user_agent" label="User agent" v-model="log.user_agent" :disabled="true" />
    <FormItem id="ip" label="IP" v-model="log.ip" :disabled="true" />
    <FormItem id="body" label="Request" v-model="log.body" type="textarea" :disabled="true" />

    <br>
    <h6>Events</h6>
    <div v-for="event in log.logs" :key="event.id" style="border-bottom: 1px solid #dee2e6;" class="mb-2">

        <FormItem id="class" label="Model" v-model="event.class" :disabled="true" />
        <FormItem id="function" label="Function" v-model="event.function" :disabled="true" />
        <template v-if="event.diff_from || event.diff_to">
            <div class="row mb-2">
                <div class="col-sm-6 col-form-label-sm">
                    Changes from<br>
                        <pre>{{event.diff_from}}</pre>
                </div>
                <div class="col-sm-6 col-form-label-sm">
                    Changes to<br>
                    <pre>{{event.diff_to}}</pre>
                </div>
            </div>
        </template>

        <div class="row mb-2">
            <div class="col-sm-6 col-form-label-sm">
                Before<br>
                <pre>{{event.before_state}}</pre>
            </div>
            <div class="col-sm-6 col-form-label-sm">
                After<br>
                <pre>{{event.after}}</pre>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LogForm',
        props: ['itemData'],
        data() {
            return {
                log: {user: {}},
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.log = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = this.log.name;
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.log;
            },
            setData(data) {
                this.log = data
                this.log.formatted_date = this.formatDate(this.log.date, 'dateTime');
            },
            reset() {
                this.log = {user: {}}
                this.loading = false
                this.invalid = {}
            }
            
        }
    }
</script>
